<template>
  <div id="container_message_box" class="container" style="min-width: 840px;">
    <div class="large-12 medium-12 small-12 cell">
      <el-row>
        <el-col :span="12">
          <div id="plan">
            <label style="font-weight: bold">Расчет плана</label>
            <br>
            <label>Количество показателей необходимых для расчета: {{local.plan.count_indicators}}</label>
            <br>
            <label>Добавлено новых показателей: {{local.plan.added_indicators}}</label>
            <br>
            <label>Обновлено показателей: {{local.plan.updated_indicators}}</label>
            <br>
            <label>Показателей с ошибкой: {{local.plan.errored_indicators}}</label>
            <br>
            <div v-if="local.plan.errored_indicators<=0"></div>
            <div v-else>
              <br>
              <label>Ошибки в расчетах по месяцам {{Object.values(local.plan.calculated.month.error).length}}:</label>
              <br>
              <label v-for="item in local.plan.calculated.month.error">{{item.indicator_code}} - {{item.error_code}}<br></label>
              <br>
              <label>Ошибки в расчетах по годам {{Object.values(local.plan.calculated.year.error).length}}:</label>
              <br>
              <label v-for="item in local.plan.calculated.year.error">{{item.indicator_code}} - {{item.error_code}}<br></label>
              <br>
              <label>Ошибки в расчетах итого {{Object.values(local.plan.calculated.total.error).length}}:</label>
              <br>
              <label v-for="item in local.plan.calculated.total.error">{{item.indicator_code}} - {{item.error_code}}<br></label>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div id="fact">
            <label style="font-weight: bold">Расчет факта</label>
            <br>
            <label>Количество показателей необходимых для расчета: {{local.fact.count_indicators}}</label>
            <br>
            <label>Добавлено новых показателей: {{local.fact.added_indicators}}</label>
            <br>
            <label>Обновлено показателей: {{local.fact.updated_indicators}}</label>
            <br>
            <label>Показателей с ошибкой: {{local.fact.errored_indicators}}</label>
            <br>
            <div v-if="local.fact.errored_indicators<=0"></div>
            <div v-else>
              <br>
              <label>Ошибки в расчетах по месяцам {{Object.values(local.fact.calculated.month.error).length}}:</label>
              <br>
              <label v-for="item in local.fact.calculated.month.error">{{item.indicator_code}} - {{item.error_code}}<br></label>
              <br>
              <label>Ошибки в расчетах по годам {{Object.values(local.fact.calculated.year.error).length}}:</label>
              <br>
              <label v-for="item in local.fact.calculated.year.error">{{item.indicator_code}} - {{item.error_code}}<br></label>
              <br>
              <label>Ошибки в расчетах итого {{Object.values(local.fact.calculated.total.error).length}}:</label>
              <br>
              <label v-for="item in local.fact.calculated.total.error">{{item.indicator_code}} - {{item.error_code}}<br></label>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ['model'],
  data () {
    return {
      file: '',
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  },
  methods: {
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    }
  }
}
</script>
